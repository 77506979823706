<template>
  <div>
    <b-form-group label="Date of Request" label-class="font-weight-bold pt-0">
      <b-form-datepicker v-model="shipment.date_of_request" reset-button></b-form-datepicker>
    </b-form-group>
    <b-card>
      <b-card-header><b-card-title>Sender Information </b-card-title> </b-card-header>
      <b-card-body>
        <b-form-group label="Sender Name" label-class="font-weight-bold pt-0">
          <b-input-group>
            <b-form-input id="sender-name" v-model="shipment.sender_name"> </b-form-input>
          </b-input-group>
        </b-form-group>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group label="Sender Phone Number" label-class="font-weight-bold pt-0">
              <b-form-input id="sender-phone" v-model="shipment.sender_phone"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group label="Sender Email" label-class="font-weight-bold pt-0">
                <b-form-input id="sender-email" v-model="shipment.sender_email"> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group label="Cost Object" label-class="font-weight-bold pt-0">
                <b-form-input list="co-list-id" v-model="shipment.extra.cost_object"> </b-form-input>
                <datalist id="co-list-id">
                  <option v-for="co in active_cost_objects" :key="co.id">{{co.id}} ({{co.description}})</option>
                </datalist>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group label="Urgency" label-class="font-weight-bold pt-0">
              <b-form-select v-model="shipment.extra.urgency">
                <template #first>
                  <b-form-select-option value="" disabled>-- Please select an option --</b-form-select-option>
                </template>
                <b-form-select-option value="high">High (next day, if possible)</b-form-select-option>
                <b-form-select-option value="medium">Medium (2-3 day delivery)</b-form-select-option>
                <b-form-select-option value="low">Low (4+ day delivery)</b-form-select-option>
                <b-form-select-option value="quickest">Quickest (regardless of price)</b-form-select-option>
                <b-form-select-option value="cheapest">Cheapest (regardless of ship length)</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>
          Recipient Information
          <b-button size="sm" variant="primary" style="float: right" @click="loadRecipient(null)">Load Saved Address</b-button>
        </b-card-title>
      </b-card-header>
      <b-modal id="load-recipient-modal" ok-only ok-title="close" title="Select a Recipient">
        <b-input placeholder="filter name" v-model="recipient_name_filter"></b-input>
        <b-row v-for="recipient in recipients_filtered" :key="recipient.id" style="margin-bottom: 5px;" no-gutters>
          <b-col cols="10">
            <b-button block variant="success" @click="loadRecipient(recipient)">
              {{recipient.id}}
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button pill variant="danger" @click="deleteRecipient(recipient)"><b-icon icon="x"></b-icon></b-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-card-body>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group label="Recipient Name" label-class="font-weight-bold pt-0">
              <b-form-input id="recipient-name" v-model="shipment.recipient.name"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group label="Company/Univ. Name" label-class="font-weight-bold pt-0">
              <b-form-input id="recipient-company" v-model="shipment.recipient.company"> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group label="Recipient Phone Number" label-class="font-weight-bold pt-0">
              <b-form-input id="recipient-phone" v-model="shipment.recipient.phone"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group label="Recipient Email" label-class="font-weight-bold pt-0">
              <b-form-input id="recipient-email" v-model="shipment.recipient.email"> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Recipient Address" label-class="font-weight-bold pt-0">
          <b-form-textarea id="recipient-address" v-model="shipment.recipient.address"> </b-form-textarea>
        </b-form-group>
        <b-row>
          <b-col cols="12" lg="4">
            <b-form-group label="City" label-class="font-weight-bold pt-0">
              <b-form-input id="recipient-city" v-model="shipment.recipient.city"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group label="State" label-class="font-weight-bold pt-0">
              <b-form-input id="recipient-state" v-model="shipment.recipient.state"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group label="Zip Code" label-class="font-weight-bold pt-0">
              <b-form-input id="recipient-zip" v-model="shipment.recipient.zip"> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Country (if International)" label-class="font-weight-bold pt-0">
          <b-form-input id="recipient-country" v-model="shipment.recipient.country"> </b-form-input>
        </b-form-group>
        <div v-if="shipment.recipient.country !== ''">
          <hr/>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group label="Number of units in package" label-class="font-weight-bold pt-0">
                <b-form-input type="number" id="recipient-n-units" v-model="shipment.content_info.units_in_pkg"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group label="Weight per unit (lbs)" label-class="font-weight-bold pt-0">
                <b-form-input type="number" id="recipient-unit-weight" v-model="shipment.content_info.unit_weight"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group label="Country of Manufacture" label-class="font-weight-bold pt-0">
                <b-form-input id="country-of-manufacture" v-model="shipment.content_info.country_of_manufacture"> </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group label="Bill Duties, Fees, and Taxes to" label-class="font-weight-bold pt-0">
                <b-form-select v-model="shipment.content_info.duties_payer">
                  <template #first>
                    <b-form-select-option value="" disabled>-- Please select an option --</b-form-select-option>
                  </template>
                  <b-form-select-option value="cost-center">Cost Object list above</b-form-select-option>
                  <b-form-select-option value="recipient">Recipient</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="shipment.content_info.duties_payer === 'recipient'" cols="12" lg="6">
              <b-form-group label="Recipient Account Number" label-class="font-weight-bold pt-0">
                <b-form-input id="country-of-manufacture" v-model="shipment.recipient.account_number"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group label="Shipment Purpose" label-class="font-weight-bold pt-0">
                <b-form-select v-model="shipment.content_info.shipment_purpose">
                  <b-form-select-option value="scientific-sample">Scientific Sample</b-form-select-option>
                  <b-form-select-option value="commercial">Commercial</b-form-select-option>
                  <b-form-select-option value="gift">Gift</b-form-select-option>
                  <b-form-select-option value="return-repair">Return / Repair</b-form-select-option>
                  <b-form-select-option value="personal-effects">Personal Effects</b-form-select-option>
                  <b-form-select-option value="personal-use">Personal Use</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div style="text-align: right">
          <b-button variant="primary" @click="$bvModal.show('save-recipient-modal')">Save Address</b-button>
          <b-modal id="save-recipient-modal" title="Provide a nickname for this Address" @ok="saveRecipient()">
            <b-input placeholder="e.g. John Doe @ UNL" v-model="recipient_nickname"></b-input>
          </b-modal>
        </div>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header> <b-card-title>Content Information</b-card-title></b-card-header>
      <b-card-body>
        <b-form-group label="Contents" label-class="font-weight-bold pt-0">
          <b-form-textarea id="content-contents" v-model="shipment.content_info.contents"> </b-form-textarea>
        </b-form-group>
        <b-form-group label="Hazard Category" label-class="font-weight-bold pt-0">
          <b-form-select v-model="shipment.content_info.content_type">
            <b-form-select-option value="non-hazardous">Non-Hazardous</b-form-select-option>
            <b-form-select-option value="hazardous">Hazardous Materials</b-form-select-option>
            <b-form-select-option value="batteries">Batteries</b-form-select-option>
            <b-form-select-option value="powdered-chemicals">Powdered Chemicals</b-form-select-option>
            <b-form-select-option value="liquid-chemicals">Liquid Chemicals</b-form-select-option>
            <b-form-select-option value="other-chemicals">Other Chemicals</b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-row>
          <b-col cols="12" lg="4">
            <b-form-group label="Package Length (inches)" label-class="font-weight-bold pt-0">
              <b-form-input id="content-length" type="number" v-model="shipment.content_info.pkg_length"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group label="Package Width (inches)" label-class="font-weight-bold pt-0">
              <b-form-input id="content-width" type="number" v-model="shipment.content_info.pkg_width"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group label="Package Height (inches)" label-class="font-weight-bold pt-0">
              <b-form-input id="content-height" type="number" v-model="shipment.content_info.pkg_height"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group label="Package Weight (lbs)" label-class="font-weight-bold pt-0">
              <b-form-input id="content-weight" type="number" v-model="shipment.content_info.pkg_weight"> </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4">
            <b-form-group label="Package Value (USD)" label-class="font-weight-bold pt-0">
              <b-form-input id="content-value" type="number" v-model="shipment.content_info.pkg_value"> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-form-group label="Comments" label-class="font-weight-bold pt-0">
      <b-form-textarea id="comments" v-model="shipment.comments"></b-form-textarea>
    </b-form-group>
    <b-button block variant="primary" @click="saveShipment">Save Shipment</b-button>
  </div>
</template>

<script>
import http from "../http-common"
import {toInteger} from "lodash";

export default {
  name: "EditShipment",
  props: [
    "active_users",
    "cost_objects",
    "loggedIn",
  ],
  data: function () {
    return {
      shipment: {
        id: -1,
        datetime_created: null,
        datetime_last_changed: null,
        last_changer: {
          full_name: '',
          username: '',
        },
        date_of_request: null,
        sender_name: '',
        sender_phone: '',
        sender_email: '',
        recipient: {
          id: null,
          company: '',
          name: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          phone: '',
          email: '',
          account_number: ''
        },
        content_info: {
          contents: '',
          content_type: '',
          pkg_length: 0,
          pkg_width: 0,
          pkg_height: 0,
          pkg_weight: 0,
          pkg_value: 0,
          units_in_pkg: 1,
          unit_weight: 0,
          country_of_manufacture: '',
          duties_payer: '',
          shipment_purpose: '',
        },
        extra: {
          cost_object: '',
          urgency: '',
        },
        comments: '',
      },
      recipient_name_filter: '',
      recipients: [],
      recipient_nickname: '',
    };
  },
  computed: {
    active_cost_objects() {
      let active_cost_objects = [];
      this.cost_objects.forEach((co) => {
        if (co.active) {
          active_cost_objects.push(co);
        }
      });
      return active_cost_objects;
    },
    recipients_filtered() {
      return this.recipients;
    }
  },
  methods: {
    saveShipment() {
      let loader = this.$loading.show();
      let ship_local = Object.assign({}, this.shipment);
      ship_local.date_of_request = ship_local.date_of_request === "" ? null : ship_local.date_of_request;
      console.log(ship_local.date_of_request);
      http.put("/shipment/"+this.shipment.id, ship_local)
      .then(() => {
        this.$router.push('/shipments');
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide();
      })
    },
    saveRecipient() {
      let recipient = this.shipment.recipient;
      http.put("/shipment/recipient", recipient, {params: {'id_': this.recipient_nickname}})
          .then(() => {
            this.$emit('show-alert', {
              message: `Address '${this.recipient_nickname}' saved successfully!`,
              type: 'success',
            });
          })
          .catch((e) => {
            http.std_error_handler(this, e);
          });

    },
    loadRecipient(recipient) {
      if (recipient === null) {
        let loader = this.$loading.show();
        http.get('/shipment/recipient/')
            .then((resp) => {
              this.recipients.length = 0;
              console.log(resp.data);
              resp.data.forEach((rec) => {
                this.recipients.push(rec);
              });
            })
            .catch((err) => {
              http.std_error_handler(this, err);
            })
            .finally(() => {
              loader.hide();
              this.$bvModal.show('load-recipient-modal');
            });
      } else {
        Object.assign(this.shipment.recipient, recipient);
        this.$bvModal.hide('load-recipient-modal');
      }
    },
    deleteRecipient(recipient) {
      if (this.shipment.recipient.id === recipient.id) {
        // disassociate loaded information with DB entry since it's going to be deleted
        this.shipment.recipient.id = null;
      }
      http.delete('/shipment/recipient/'+recipient.id)
          .then(() => {
            this.loadRecipient(null);
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
    },
  },
  mounted() {
    let loader = this.$loading.show();
    let shipment_id = toInteger(this.$route.params.id);
    http.get('/shipment/'+shipment_id)
    .then((resp) => {
      let s = resp.data;
      this.shipment.id = s.id;
      this.shipment.datetime_created = new Date(s.datetime_created);
      this.shipment.datetime_last_changed = new Date(s.datetime_last_changed);
      this.shipment.last_changer.full_name = s.last_changer.full_name;
      this.shipment.last_changer.username = s.last_changer.username;
      this.shipment.date_of_request = s.date_of_request;
      this.shipment.sender_name = s.sender_name;
      this.shipment.sender_phone = s.sender_phone;
      this.shipment.sender_email = s.sender_email;
      this.shipment.comments = s.comments;

      this.shipment.recipient.company = s.recipient.company || '';
      this.shipment.recipient.name = s.recipient.name || '';
      this.shipment.recipient.address = s.recipient.address || '';
      this.shipment.recipient.city = s.recipient.city || '';
      this.shipment.recipient.state = s.recipient.state || '';
      this.shipment.recipient.zip = s.recipient.zip || '';
      this.shipment.recipient.country = s.recipient.country || '';
      this.shipment.recipient.phone = s.recipient.phone || '';
      this.shipment.recipient.email = s.recipient.email || '';
      this.shipment.recipient.account_number = s.recipient.account_number || '';

      this.shipment.content_info.contents = s.content_info.contents || '';
      this.shipment.content_info.content_type = s.content_info.content_type || '';
      this.shipment.content_info.pkg_length = s.content_info.pkg_length || 0;
      this.shipment.content_info.pkg_width = s.content_info.pkg_width || 0;
      this.shipment.content_info.pkg_height = s.content_info.pkg_height || 0;
      this.shipment.content_info.pkg_weight = s.content_info.pkg_weight || 0;
      this.shipment.content_info.pkg_value = s.content_info.pkg_value || 0;
      this.shipment.content_info.units_in_pkg = s.content_info.units_in_pkg || 1;
      this.shipment.content_info.unit_weight = s.content_info.unit_weight || 0;
      this.shipment.content_info.country_of_manufacture = s.content_info.country_of_manufacture || '';
      this.shipment.content_info.duties_payer = s.content_info.duties_payer || '';
      this.shipment.content_info.shipment_purpose = s.content_info.shipment_purpose || '';

      this.shipment.extra.cost_object = s.extra.cost_object || '';
      this.shipment.extra.urgency = s.extra.urgency || '';
    })
    .catch((err) => {
      http.std_error_handler(this, err);
    })
    .finally(() => {
      loader.hide();
    })

    this.$emit('sync-cost-objects');
  }
}
</script>

<style scoped>

</style>